import './App.css';
import HomePage from './pages/Home';

function App() {
  return (
    <HomePage />
  );
}

export default App;
